export const dataTour = [

    {
        "date": "sabado 25 de mayo",
        "name": "Comedor La Gringa",
        "time": "Horario a confirmar",
        "place": "Juan Bautista Alberdi",
        "url": "https://maps.app.goo.gl/LhVMGwgV7dQCdctj8",
        "src": "img/loj-alberdi.png"
    }

]

//Plantilla datos 
/*
 {
        "date": "Sabado 4 febrero",
        "name": "Flow Almacen de Pizzas",
        "time": "21:00 Hs.",
        "place": "Vedia - Buenos Aires",
        "url": "https://goo.gl/maps/NzoNR6SnWx2qjYECA",
        "src": "img/flow.webp"
    }

*/