export const dataGallery = [
    {
        img: "img/1.jpg",
        rows: 2,
        title: "La Otra Junta - Facu"
    },
    {
        img: "img/2.jpg",
        rows: 2,
        cols: 1,
        title: "La Otra Junta - Nico"
    },
    {
        img: "img/3.jpg",
        rows: 3,
        title: "La Otra Junta - Paulo"
    },
    {
        img: "img/4.jpg",
        rows: 2,
        cols: 2,
        title: "La Otra Junta - Toska"
    },
    {
        img: "img/5.jpg",
        rows: 2,
        title: "La Otra Junta - Facu"
    },
    {
        img: "img/6.jpg",
        rows: 2,
        title: "La Otra Junta - Paulo"

    },
    {
        img: "img/7.jpg",
        rows: 1,
        title: "La Otra Junta - Berna"
    },
    {
        img: "img/8.jpg",
        rows: 2,
        title: "La Otra Junta - Banda"
    },
    {
        img: "img/9.jpg",
        rows: 2,
        cols: 1,
        title: "La Otra Junta - Banda"
    },
    {
        img: "img/10.jpg",
        rows: 1,
        title: "La Otra Junta - Banda"
    }
]